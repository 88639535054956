import React, { useCallback, useContext, useState, useRef } from "react";
import { Modal, Slider } from "antd";
import Cookies from "js-cookie";
import { Context } from "../../AccelProvider/AccelProvider";
import { ApiResponse, ApiResult } from "../../../api";
import { ErrorCode } from "../../../enums";
import styles from './CaptchaModalContent.module.scss';
import HelpText from "../../HelpText/HelpText";

const CAPTCHA_COOKIE_NAME = 'Captcha';

type CaptchaBody = {
    puzzleImageBytes: string;
    backgroundImageBytes: string;
    positionY: number;
}

type CaptchaValidateModel = {
    key: string;
    positionX: number;
}

const getCaptchaCookie = (): CaptchaValidateModel | null => {
    const cookie = Cookies.get(CAPTCHA_COOKIE_NAME);
    if (!cookie) return null;

    try {
        return JSON.parse(cookie) as CaptchaValidateModel;
    } catch (e) {
        console.error('Failed to get captcha cookie', e);
        return null;
    }
};

const setCaptchaCookie = (data: CaptchaValidateModel): void => {
    try {
        Cookies.set(CAPTCHA_COOKIE_NAME, JSON.stringify(data), { path: '/' });
    } catch (e) {
        console.error('Failed to set captcha cookie', e);
    }
};

const CaptchaModalContent = React.memo<{
    captcha: CaptchaBody;
    setOffsetX: (x: number) => void;
}>(({ captcha, setOffsetX }) => {
    const [localOffsetX, setLocalOffsetX] = useState(0);
    const [imageWidth, setImageWidth] = useState(0);
    const [puzzleWidth, setPuzzleWidth] = useState(0);

    const handleBackgroundLoad = useCallback((e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        setImageWidth((e.target as HTMLElement).clientWidth);
    }, []);

    const handlePuzzleLoad = useCallback((e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        setPuzzleWidth((e.target as HTMLElement).clientWidth);
    }, []);

    return <div className="text-center relative no-select">
        <div className="d-inline-block relative overflow-hidden br-xxs">
            <img src={`data:image/png;base64,${captcha.backgroundImageBytes}`}
                alt="background"
                className="max-w-100 d-block"
                draggable={false}
                onLoad={handleBackgroundLoad} />
            <img src={`data:image/png;base64,${captcha.puzzleImageBytes}`}
                alt="puzzle"
                className="absolute no-pointer-events z-1"
                style={{
                    top: captcha.positionY,
                    left: localOffsetX,
                }}
                draggable={false}
                onLoad={handlePuzzleLoad} />
        </div>

        <div style={{ width: `${imageWidth}px`, margin: '0 auto' }}>
            <div className={styles.customSlider}>
                <Slider value={localOffsetX}
                    min={0}
                    max={imageWidth > 0 ? imageWidth - (puzzleWidth || 60) : 300}
                    onChange={setLocalOffsetX}
                    onAfterChange={setOffsetX}
                    className="w-100"
                    tooltip={{ formatter: null }} />
            </div>
        </div>
    </div>;
});

export const useCaptcha = () => {
    const { loc } = useContext(Context);
    const offsetXRef = useRef(0)

    const validate = useCallback(async (captchaData: CaptchaBody, cb?: () => Promise<void>) => {
        if (!captchaData) return;

        const captchaDataFromCookie = getCaptchaCookie();
        if (captchaDataFromCookie) {
            captchaDataFromCookie.positionX = offsetXRef.current;
            setCaptchaCookie(captchaDataFromCookie);
        }

        Modal.destroyAll();
        offsetXRef.current = 0;
        if (cb) await cb();
    }, []);

    const checkResponse = useCallback((result: ApiResult<ApiResponse>, cb?: () => Promise<void>) => {
        if (!result.response.hasError(ErrorCode.CaptchaNeedSend))
            return false;

        const captchaData = result.response.body as CaptchaBody;
        const captchaCookie = getCaptchaCookie();
        if (!captchaData?.puzzleImageBytes || !captchaData?.backgroundImageBytes || !captchaCookie?.key)
            return false;

        offsetXRef.current = 0;

        Modal.info({
            title: <div>
                <div className="fw-500 fs-16">{loc.word('Captcha.title', { default: 'Please solve the puzzle' })}</div>
                <HelpText label={loc.word('Captcha.helpText', { default: 'Move the slider to the right position' })} className="fw-400 fs-12" />
            </div>,
            content: <CaptchaModalContent
                key={captchaCookie.key}
                captcha={captchaData}
                setOffsetX={x => offsetXRef.current = x}
            />,
            icon: null,
            centered: true,
            maskClosable: false,
            okText: loc.word('Global.done', { default: 'Done' }),
            bodyStyle: { padding: "8px 12px" },
            width: 'auto',
            onOk: () => {
                setCaptchaCookie({
                    key: captchaCookie.key,
                    positionX: offsetXRef.current
                });
                validate(captchaData, cb);
            }
        });

        return true;
    }, [validate]);

    return { checkResponse };
};