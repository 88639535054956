import { action, observable } from 'mobx';
import moment, { type Moment } from 'moment';
import { Contact, Entity, Quiz, QuizItemAnswer } from '../..';

export default class QuizAnswer extends Entity {
    constructor(item?: Partial<QuizAnswer>) {
        super(item);
        if (item) this.update(item);
    }

    @observable quizId: string;

    @observable createdDate: Moment;
    @observable completionDate: Moment;

    @observable currentPageId: string;

    @observable contactId: string;
    @observable siteGuestId: string;
    @observable siteSessionViewId: string;

    @observable siteId: string;
    @observable sitePageId: string;
    @observable widgetId: string;
    @observable url: string;

    @observable uTMSource: string;
    @observable uTMMedium: string;
    @observable uTMCampaign: string;
    @observable uTMTerm: string;
    @observable uTMContent: string;

    @observable answers: QuizItemAnswer[];
    @observable contact?: Contact;
    @observable snapshotId: string;
    @observable quiz: Quiz;

    @action update(q: Partial<QuizAnswer>, allowUndefined = false) {
        super.update(q, allowUndefined);
    }

    static fromJson(json: any): QuizAnswer {
        return new QuizAnswer({
            ...json,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            completionDate: json.completionDate ? moment(json.completionDate) : undefined,
            answers: json.answers ? json.answers.map(QuizItemAnswer.fromJson) : [],
            contact: json.contact ? Contact.fromJson(json.contact) : undefined,
            quiz: json.quiz ? Quiz.fromJson(json.quiz) : undefined
        });
    }
}